html,
body,
#root,
.section {
  height: 100%;
}

.authentication-page {
  height: 100%;
  position: relative;
  opacity: 0.8;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-card {
  padding: 0 1em 1.5em 1em;
  border-radius: 6px;
}

.login-logo {
  margin-bottom: 0.5rem;
}

.main-page {
  padding-top: 5rem;
}

.steps,
.order-steps-navigation {
  margin-top: 2rem;
}

.section-title {
  padding-bottom: 1rem;
}

.padded-left-less {
  padding-left: 0.5rem;
}

.padded-left {
  padding-left: 1rem;
}

.columns-content {
  padding-top: 1rem;
}

.clickable {
  cursor: pointer;
}

.card > .card-header {
  min-height: 5rem;
}

.input-password-error {
  margin-top: -0.5rem;
}

.clickable.card {
  transition: all 0.2s;
}

.clickable.card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

a.tile {
  transition: all 0.2s;
}

a.tile:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.distance-left {
  margin-left: 0.5rem;
}

.modal-background {
  background-color: rgba(10, 10, 10, 0.5);
}

.form-buttons {
  margin-top: 1rem;
}

.form-wizard-buttons {
  margin-top: 10rem;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.table-container {
  position: relative;
  margin-bottom: 1.5rem;
}

nav {
  position: relative;
}

.navbar-link-active {
  background-color: #00b89c;
}

div.loading {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.columns.compact {
  margin-bottom: 0;
}

hr.compact {
  margin: 0.5rem 0;
}

.level.compact {
  margin-bottom: -1.5rem;
}

.orderable {
  cursor: pointer;
}

.is-removed {
  display: none;
}

.tabs.tab-panel-header {
  margin-bottom: 0;
}

.tabs.is-toggle.tab-panel-header li a {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.tab-panel-body {
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: rgba(10, 10, 10, 0.1);
  border-radius: 0 0 4px 4px;
  padding: 1.25rem;
  margin-bottom: 1.5rem;
}

.checkbox > span {
  padding-left: 0.5rem;
}

.radio {
  margin-right: 1rem;
}

.radio.vertical {
  display: block;
  margin: 0.2rem 0;
}

.radio > input[type="radio"] {
  margin-right: 0.2rem;
}

.steps:not(.is-hollow) .steps-marker:not(.is-hollow),
.steps:not(.is-hollow) .steps-segment.is-active .steps-marker:not(.is-hollow),
.steps-segment:after {
  background-color: #00d1b2;
}

.partecipant-label {
  font-size: 1.5rem;
  padding-left: 0.8rem;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.order-partecipation-column {
  padding-left: 1rem;
  margin-top: 0rem;
  transition: all 0.2s;
}

.order-partecipation-column:hover {
  background-color: rgba(0,0,0,0.1);
}

.event-type-image {
  width: 300px;
}

#payment-button-container {
  position: relative;
  width: 50%;
  margin: 0 auto;
  margin-top: 3rem;
}

.paypal-buttons-context-iframe {
  z-index: 1;
}

.notification.is-info.is-light{
  background-color:#eef6fc;color:#1d72aa
}

.login-button {
  background-color: #fc5640;
  border: 0;
  border-radius: 50px;
  width: 100%;
  height: 15%;
  margin-top: 2%;
  font-size : 100%;
  }

@media (max-width: 770px) {
  .login-button {
    padding: 2%;
  }
}

@media screen and (min-width: 769px) {
  .column.right-column {
    border-left: 1px solid rgba(10, 10, 10, 0.1);
  }
}

@media screen and (min-width: 1024px) {
  .modal-content,
  .modal-card {
    width: 900px;
  }
}

@media screen and (min-width: 1216px) {
  .modal-content,
  .modal-card {
    width: 1100px;
  }

  .modal.is-large > .modal-content,
  .modal.is-large > .modal-card, 
  .modal.is-large > .modal-card > .modal-card-form > .modal-card-body {
    width: 1400px;
    min-height: 500px;
  }
}

@media screen and (min-width: 1408px) {
  .modal-content,
  .modal-card {
    width: 1300px;
  }

  .modal.is-large > .modal-content,
  .modal.is-large > .modal-card {
    width: 1400px;
    min-height: 600px;
  }
}

